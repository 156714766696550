<template>
  <div class="ape-container">
    <!--    <div ref="Clouds" style="display:inline-block;width:100vw;height: 100vh;">-->
    <!--    </div>-->
    <div class="ape-content">
      <!--路由视图-->
      <router-view/>
    </div>
    <div class="bgRight" >
      <img :src="bgRight">
    </div>
    <!--底部-->
    <Footer/>
  </div>
</template>
<script>
import Footer from './Footer'
import bgRight from '@/assets/bg-right.png'

export default {
  name: 'PassportLayout',
  components: {
    Footer
  },
  data() {
    return {
      bgRight:bgRight
      // vantaEffect: null

    }
  },
  mounted() {
  },
}
</script>

<style lang="stylus">
$screen-md-min = 768px
$heading-color = rgba(0, 0, 0, 0.85)
$font-size-base = 14px
$text-color-secondary = rgba(0, 0, 0, 0.45)

.ape-container
  display flex
  flex-direction column
  height 100vh
  overflow hidden !important
  background-image: linear-gradient(to bottom, #f9f7e0, #e9dce7, #ffa8b3);


//border 1px solid red

.ape-content
  padding 32px 0
  flex 1

@media (min-width $screen-md-min)
  .ape-container
    background-image url("https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg")
    //#c0cad8
    //background-image: linear-gradient(to bottom left, #c0cad8, #e8e8d7, #bcc28c);
    //background-image: linear-gradient(to bottom, #f9f7e0, #e9dce7, #ffa8b3);
    background-repeat no-repeat
    background-position center
    background-size 100%


  .ape-content
    padding 12% 0 24px 0

.ape-top
  text-align center
  padding-top 10px

.ape-header
  height 44px
  line-height 44px

  a
    text-decoration none

.ape-logo
  height 60px
  vertical-align top
  margin-right 4px

.ape-title
  font-size 33px
  color $heading-color
  font-family "Myriad Pro", "Helvetica Neue", Arial, Helvetica, sans-serif
  font-weight 600
  position relative
  top -2px

.ape-desc
  font-size $font-size-base
  margin-top 18px
  color $--festival--color
  margin-bottom 32px
</style>
<style lang="scss" scoped>

.bgRight {
  width: 487px;
  height: 412px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;

  > img {
    width: 100%;
    height: auto;
    display: block;
  }
}
</style>
