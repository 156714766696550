<template>
  <div class="mars-ape-layout-footer">
    <div class="mars-ape-layout-footer-copyright">
      Copyright <i class="iconfont icon-ic_copyright"></i> 2021~2024 杭州缇苏文化传播有限公司 | 备案号：<a
        href="https://beian.miit.gov.cn/" target='_blank'>浙ICP备18052836号-1</a>
    </div>
    <div class="mars-ape-layout-footer-link">
    </div>
  </div>
</template>
<script>

export default {
  name: 'Footer'
}
</script>
<style lang="stylus">
.mars-ape-layout-footer
  font-size 14px
  padding 0 16px
  //margin 18px 0 18px
  text-align center
  //color white


.mars-ape-layout-footer-link
  margin-bottom 8px

.mars-ape-layout-footer-copyright
  a
    color #1890ff
    text-decoration none
</style>
<style>
.mars-ape-layout-footer {
  /*background-image: url("../src/assets/footer.png");*/
}
</style>
